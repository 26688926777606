<template>
    <section class="bg-white pt-4 pt-md-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <h1 class="bold mb-md-4">{{$t('autoprotectAccount.text1')}} <span class="secondary">{{$t('autoprotectAccount.text1a')}}</span></h1>
                    <div class="zuluGuard my-3 my-md-4">
                        <img src="../../../public/assets/images/zuluguard.png" :alt="$t('autoprotectAccount.text4')" :title="$t('autoprotectAccount.text3')">
                    </div>
                    <div class="text-center mt-4 mt-md-0">
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn">{{$t('home.text30')}}</router-link>
                        <a v-else class="button fillBtn zulu_btn" :href="'/register/'">{{$t('home.text30')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="bg-white autoprotect2 pt-4 pt-md-5">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6">
                    <img width="522" class="d-block mx-auto" src="/assets/images/zuluguard-floor-1.png" :alt="$t('autoprotectAccount.text12')" :title="$t('autoprotectAccount.text5')">
                </div>
                <div class="col-12 col-md-6 text-center text-md-start">
                    <h2 class="mb-2 mt-2 mt-md-0">{{$t('autoprotectAccount.text5')}}</h2>
                    <p>{{$t('autoprotectAccount.text7')}}</p>
                    <p>{{$t('autoprotectAccount.text8')}}</p>
                </div>
            </div>
        </div>
    </section>
    <section class="bg-white autoprotect2 py-4 py-md-5">
        <div class="container">
            <div class="row flex-row-reverse">
                <div class="col-12 col-md-6">
                    <img width="522" class="d-block mx-auto" src="/assets/images/zuluguard-new-1.png" :alt="$t('autoprotectAccount.text9')" :title="$t('autoprotectAccount.text9')">
                </div>
                <div class="col-12 col-md-6 text-center text-md-start">
                    <h2 class="mb-2 mt-2 mt-md-0">{{$t('autoprotectAccount.text9')}}</h2>
                    <p>{{$t('autoprotectAccount.text10')}}</p>
                    <p>{{$t('autoprotectAccount.text11')}}</p>
                    <router-link to="/zuluguard-guide" class="secondary">{{$t('autoprotectAccount.text12')}}</router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
    }
</script>